import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { Spin as Hamburger } from 'hamburger-react';
import Scrollspy from 'react-scrollspy';
import navItems from '../data/navItems';
import { Share } from './icons';
import ShareDialog from './shareDialog';

// Used on the mobile viewport but not on large screens
// because scrollspy wont work if you use the component so JSX is used directly
const SideNavItem = ({ title, icon, toggleMobileNav }) => {
  const onClick = (e) => {
    const { route } = e.currentTarget.dataset;
    navigate(route);
    toggleMobileNav();
  };
  return (
    <div
      className="flex items-center px-2 pt-2 pb-1 mt-2 text-gray-500 transition duration-75 ease-in-out transform border-2 border-transparent rounded-full cursor-pointer dark:text-gray-200 hover:border-gray-00 hover:bg-cloud-100 dark:hover:bg-cloud-700 dark:hover:text-indigo hover:text-indigo"
      data-route={`/resources/#${title.toLowerCase()}`}
      onClick={onClick}
    >
      <span className="mb-1 ml-2">{icon}</span>

      <span className="mx-4 text-lg">{title}</span>
    </div>
  );
};

const BuyMeCoffeeIcon = ({ props }) => (
  <svg
    width={42}
    height={42}
    viewBox="0 0 24 36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
		>
    <title>Group</title>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FF9100"
        d="M11.835 7.655l-8.74-.052 4.292 27.113h10.456l4.292-27.113z"
      />
      <path
        fill="#FD0"
        d="M11.835 7.655l-8.74-.052 4.292 27.113h8.272L19.95 7.603z"
      />
      <path fill="#FFF" d="M.599 7.603h22.55v-2.5H.6z" />
      <path
        stroke="#000"
        strokeWidth={1.17}
        d="M.599 7.603h22.55v-2.5H.6z"
      />
      <path fill="#FFF" d="M18.78 1.04H4.813l-1.64 3.75H20.419z" />
      <g strokeWidth={1.17}>
        <path
          stroke="#050505"
          d="M18.78 1.04H4.813l-1.64 3.75H20.419z"
        />
        <path
          stroke="#000"
          d="M11.835 7.655l-10.3-.052 4.292 27.113h12.016l4.292-27.113z"
        />
      </g>
      <path
        fill="#FFF"
        d="M22.447 15.26H1.146l1.922 10.783 8.728-.094 8.728.094z"
      />
      <path
        stroke="#000"
        strokeWidth={1.17}
        d="M22.447 15.26H1.146l1.922 10.783 8.728-.094 8.728.094z"
      />
    </g>
  </svg>
);

const SideBar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div>
      <ShareDialog isOpen={showModal} setIsOpen={setShowModal} />
      <div className="fixed z-10 hidden w-full h-screen max-w-xs bg-white shadow xl:block dark:bg-cloud-600 border-cloud-500 text-lightBlue-500">
        <Link to="/" className="">
          <div className="py-8 text-5xl font-bold tracking-tighter text-center cursor-pointer bg-cloud-50 dark:bg-cloud-700 text-indigo">
            Akshay
          </div>
        </Link>
        <div className="w-auto p-8 pt-0 ">
          <div className="w-64 h-screen ">
            <nav>
              <Scrollspy
                items={navItems.map((item) => item.title.toLowerCase())}
                currentClassName="nav-active"
                offset={-100}
              >
                {navItems.map(({ title, icon }) => (
                  <a
                    href={`#${title.toLowerCase()}`}
                    className="flex items-center px-2 pt-2 pb-1 mt-2 text-gray-500 transition duration-75 ease-in-out transform border-2 border-transparent rounded-full cursor-pointer dark:text-gray-200 hover:text-indigo "
                  >
                    <span className="mb-1 ml-2">
                      {icon}
                    </span>

                    <span className="mx-4 text-lg">
                      {title}
                    </span>
                  </a>
                ))}
              </Scrollspy>
            </nav>
          </div>
        </div>
        <div className="fixed bottom-0 w-full max-w-xs ">
          <div
            data-splitbee-event="Share Modal"
            className="flex items-center px-6 py-4 pl-8 space-x-6 font-bold text-white cursor-pointer bg-blue"
            onClick={() => setShowModal(true)}
          >
            <Share className="w-10 h-10 text-white" />
            <p>
              <span className="text-xl ">Share with Friends</span>
              <br />
              <span className="text-sm ">
                Help spread the word
              </span>
            </p>
          </div>
          <a
            data-splitbee-event="Buy Coffee"
            rel="noopener noreferrer"
            className="flex items-center px-6 py-4 pl-8 space-x-6 font-bold text-white bg-green"
            href="https://www.buymeacoffee.com/awsmakshay"
            target="_blank"
          >
            <BuyMeCoffeeIcon />
            <p>
              <span className="text-xl font-bold">
                Buy me a Coffee
              </span>
              <br />
              <span className="text-sm ">
                Help me keep this site alive
              </span>
            </p>
          </a>
        </div>
      </div>
      {/* Mobile navigation  */}
      <nav className="fixed top-0 left-0 z-30 w-full bg-white shadow-md lg:hidden dark:bg-cloud-800 ">
        <div className="flex items-center px-6 py-4 space-between ">
          <div className="flex-grow text-4xl font-bold cursor-pointer text-indigo ">
            <Link to="/">Akshay</Link>
          </div>
          <div className="cursor-pointer text-indigo">
            <Hamburger
              toggled={isMobileNavOpen}
              toggle={toggleMobileNav}
            />
          </div>
        </div>

        {isMobileNavOpen && (
        <>
          <div className="flex flex-col h-screen px-6 pt-4 bg-cloud-100 dark:bg-cloud-700">
            {navItems.map(({ title, icon }) => (
              <SideNavItem
                title={title}
                icon={icon}
                toggleMobileNav={toggleMobileNav}
              />
            ))}
          </div>

          <div className="fixed bottom-0 w-full">
            <div
              data-splitbee-event="Share Modal"
              className="flex items-center px-6 py-4 pl-8 space-x-6 font-bold text-white bg-blue"
              onClick={() => {
								  setShowModal(true);
								  setIsMobileNavOpen(false);
              }}
            >
              <Share className="w-10 h-10 text-white" />

              <p>
                <span className="text-xl">
                  Share with Friends
                </span>
                <br />
                <span className="text-sm ">
                  Help spread the word
                </span>
              </p>
            </div>
            <a
              data-splitbee-event="Buy Coffee"
              rel="noopener noreferrer"
              className="flex items-center px-6 py-4 pl-8 space-x-6 font-bold text-white bg-green"
              href="https://www.buymeacoffee.com/awsmakshay"
              target="_blank"
            >
              <BuyMeCoffeeIcon />
              <p>
                <span className="text-xl font-bold">
                  Buy me a Coffee
                </span>
                <br />
                <span className="text-sm ">
                  Help me keep this site alive
                </span>
              </p>
            </a>
          </div>
        </>
        )}
      </nav>
    </div>
  );
};

export default SideBar;
