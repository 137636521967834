import React from 'react';

const navItems = [
  {
    title: 'Icons',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M88,160a70.51259,70.51259,0,0,0,.73242,7.26758A70.51259,70.51259,0,0,0,96,168a71.99981,71.99981,0,0,0,72-72,70.51259,70.51259,0,0,0-.73242-7.26758A70.51259,70.51259,0,0,0,160,88,71.99981,71.99981,0,0,0,88,160Z"
          opacity="0.2"
        />
        <circle
          cx="96"
          cy="96"
          r="72"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <circle
          cx="160"
          cy="160"
          r="72"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
  {
    title: 'Illustrations',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <path
          d="M120.00586,56.00342,60.3291,78.38232a7.99941,7.99941,0,0,0-5.08227,6.1753l-23.241,139.4458,139.4458-23.24121a7.99949,7.99949,0,0,0,6.17529-5.082l22.37891-59.67676ZM116,160a20,20,0,1,1,20-20A19.9999,19.9999,0,0,1,116,160Z"
          opacity="0.2"
        />
        <circle
          cx="116"
          cy="140"
          r="20"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="32.00586"
          y1="224.00342"
          x2="101.85786"
          y2="154.14214"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M32.00586,224.00342l139.44573-23.241a8,8,0,0,0,6.17544-5.08216l22.37883-59.67688-80-80L60.329,78.38225a8,8,0,0,0-5.08217,6.17544Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M200.00586,136.00342,226.349,109.66027a8,8,0,0,0,0-11.31371l-68.6863-68.68629a8,8,0,0,0-11.3137,0L120.00586,56.00342"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
  // {
  //   title: "tailwind css",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       stroke="currentColor"
  //       className="w-7 h-7 "
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
  //       />
  //     </svg>
  //   ),
  // },
  {
    title: 'UI Design',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M195.88232,60.11774A96.00007,96.00007,0,1,0,88,215.28711V152a8,8,0,0,1,8-8h8V96l48-24v72h8a8,8,0,0,1,8,8v63.28711A96.03847,96.03847,0,0,0,195.88232,60.11774Z"
          opacity="0.2"
        />
        <circle
          cx="128"
          cy="128"
          r="96"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <polyline
          points="104 144 104 96 152 72 152 144"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M168,215.29789V152a8,8,0,0,0-8-8H96a8,8,0,0,0-8,8v63.29789"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
  {
    title: 'Learning',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M224,56H160a32.00018,32.00018,0,0,0-32,32A31.99986,31.99986,0,0,0,96,56H32a7.99993,7.99993,0,0,0-8,8V192a7.99993,7.99993,0,0,0,8,8H96a31.99986,31.99986,0,0,1,32,32,32.00018,32.00018,0,0,1,32-32h64a7.99993,7.99993,0,0,0,8-8V64A7.99993,7.99993,0,0,0,224,56Z"
          opacity="0.2"
        />
        <path
          d="M128,88a32,32,0,0,1,32-32h64a8,8,0,0,1,8,8V192a8,8,0,0,1-8,8H160a32,32,0,0,0-32,32"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M24,192a8,8,0,0,0,8,8H96a32,32,0,0,1,32,32V88A32,32,0,0,0,96,56H32a8,8,0,0,0-8,8Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
  {
    title: 'Blogs',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M32,200a16,16,0,0,0,16-16V64a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a16,16,0,0,1-16,16Z"
          opacity="0.2"
        />
        <line
          x1="96"
          y1="112"
          x2="176"
          y2="112"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="96"
          y1="144"
          x2="176"
          y2="144"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M32,200a16,16,0,0,0,16-16V64a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a16,16,0,0,1-16,16Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M32,200a16,16,0,0,1-16-16V88"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
  {
    title: 'Color',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M216,112v32a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V112Z"
          opacity="0.2"
        />
        <path
          d="M112,152l-8,56a24,24,0,0,0,48,0l-8-56Z"
          opacity="0.2"
        />
        <path
          d="M72,32H208a8,8,0,0,1,8,8V144a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64A32,32,0,0,1,72,32Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M112,152l-8,56a24,24,0,0,0,48,0l-8-56"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="40"
          y1="112"
          x2="216"
          y2="112"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="168"
          y1="32"
          x2="168"
          y2="72"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
  {
    title: 'Miscellaneous',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <rect x="48" y="48" width="64" height="64" opacity="0.2" />
        <rect
          x="144"
          y="48"
          width="64"
          height="64"
          opacity="0.2"
        />
        <rect
          x="48"
          y="144"
          width="64"
          height="64"
          opacity="0.2"
        />
        <rect
          x="144"
          y="144"
          width="64"
          height="64"
          opacity="0.2"
        />
        <rect
          x="144"
          y="144"
          width="64"
          height="64"
          strokeWidth="16"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <rect
          x="48"
          y="48"
          width="64"
          height="64"
          strokeWidth="16"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <rect
          x="144"
          y="48"
          width="64"
          height="64"
          strokeWidth="16"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <rect
          x="48"
          y="144"
          width="64"
          height="64"
          strokeWidth="16"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
  },
  {
    title: 'YouTube',
    icon: (
      <svg
        className="w-7 h-7 "
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none" />
        <path
          d="M226.58887,71.81348A15.91692,15.91692,0,0,0,216.95605,60.919C183.479,48.0542,128,48.40747,128,48.40747S72.52148,48.0542,39.044,60.919a15.91766,15.91766,0,0,0-9.63282,10.89428C27.07422,80.78613,24,98.24438,24,128c0,29.75537,3.07422,47.21387,5.41113,56.18652A15.91692,15.91692,0,0,0,39.044,195.08105C72.521,207.9458,128,207.59253,128,207.59253s55.47852.35327,88.95605-12.51148a15.91655,15.91655,0,0,0,9.63282-10.89428C228.92578,175.21387,232,157.75562,232,128,232,98.24463,228.92578,80.78613,226.58887,71.81348ZM112,160V96l48,32Z"
          opacity="0.2"
        />
        <polygon
          points="160 128 112 96 112 160 160 128"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <path
          d="M24,128c0,29.75546,3.07414,47.21382,5.41108,56.18664a15.91682,15.91682,0,0,0,9.63282,10.89441C72.52083,207.94575,128,207.5925,128,207.5925s55.47857.35324,88.9558-12.51137a15.91684,15.91684,0,0,0,9.6329-10.89432C228.92572,175.214,232,157.75554,232,128s-3.07415-47.21382-5.41108-56.18664A15.91682,15.91682,0,0,0,216.9561,60.919C183.47917,48.05425,128,48.4075,128,48.4075s-55.47857-.35324-88.9558,12.51137a15.91684,15.91684,0,0,0-9.6329,10.89432C27.07427,80.786,24,98.24446,24,128Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    ),
  },
];

export default navItems;
