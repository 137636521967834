import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <>
          <input
            id="toggle"
            className="toggle "
            type="checkbox"
            onChange={(e) => toggleTheme(e.target.checked ? 'light' : 'dark')}
            checked={theme === 'light'}
          />
          <label htmlFor="toggle" />
        </>
      )}
    </ThemeToggler>
  );
}
