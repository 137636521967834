import React from 'react';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ml-2 "
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="5" y1="12" x2="19" y2="12" />
    <line x1="15" y1="16" x2="19" y2="12" />
    <line x1="15" y1="8" x2="19" y2="12" />
  </svg>
);
const PrimaryButton = ({ className, children, ...restProps }) => (
  <button
    {...restProps}
    className={`${className} flex items-center px-6 pt-4 pb-3 mt-auto font-bold text-white transition duration-500 ease-in-out transform border-2 border-none rounded-lg bg-gradient-to-br from-indigo-400 to-indigo-500 dark:bg-indigo-700 dark:hover:bg-indigo-600 hover:bg-indigo-600  font-lg focus:outline-none focus:ring focus:ring-indigo-400 focus:ring-opacity-50 `}
		>
    <div className="flex items-center">
      {children}
      <span>
        <ArrowIcon />
      </span>
    </div>
  </button>
);

export default PrimaryButton;
