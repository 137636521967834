import React, { useState } from "react"
import useSWR from "swr"
import { default as MasnoryContainer } from "react-masonry-css"
import { syncWithStorage } from "swr-sync-storage"
import SEO from "../components/seo"
import SideBar from "../components/sideBar"
import resources from "../data/resources"
import ThemeToggle from "../components/themeToggle"

if (typeof window !== "undefined") {
	syncWithStorage("local")
}
const fetcher = (...args) => fetch(...args).then(res => res.json())

const Card = ({ url }) => {
	const [imageLoaded, setImageLoaded] = useState(true)

	const { data: siteInfo } = useSWR(
		`https://micro-open-graph-tau.vercel.app/?url=${url}`,
		fetcher
	)

	if (!siteInfo) {
		return (
			<>
				<div className="w-full py-4 ">
					<div className="animate-pulse">
						<div className="w-full rounded-t-lg h-44 bg-cloud-200 dark:bg-cloud-700" />
						<div className="p-6 bg-white rounded-b-lg dark:bg-cloud-600 ">
							<div className="w-3/4 h-6 mb-3 rounded bg-cloud-200 dark:bg-cloud-700" />
							<div className="w-full h-6 mb-3 rounded bg-cloud-200 dark:bg-cloud-700" />
							<div className="w-full h-6 mb-3 rounded bg-cloud-200 dark:bg-cloud-700" />
							<div className="w-3/4 h-6 mb-5 rounded bg-cloud-200 dark:bg-cloud-700" />
						</div>
					</div>
				</div>
			</>
		)
	}
	return (
		<>
			{siteInfo && (
				<div className="py-4 w-100">
					<a href={url} target="_blank" rel="noopener noreferrer">
						<div className="transition duration-200 transform bg-white rounded-lg dark:bg-cloud-700 group hover:shadow-xl hover:scale-105 ">
							{imageLoaded && siteInfo.image && (
								<img
									onError={() => setImageLoaded(false)}
									className="object-cover object-center w-full rounded-t-lg "
									src={siteInfo.image}
									alt={siteInfo.title}
								/>
							)}
							<div className="p-6">
								<h2 className="mb-1 text-3xl font-bold text-gray-900 dark:text-white title-font ">
									{" "}
									{siteInfo.title}
								</h2>
								<p className="mb-4 text-lg leading-relaxed text-gray-500 break-words line-clamp-6 dark:text-gray-200">
									{siteInfo.description}
								</p>
							</div>
						</div>
					</a>
				</div>
			)}
		</>
	)
}

const Resources = () => {
	const breakpointColumnsObj = {
		default: 3,
		1100: 3,
		1000: 2,
		990: 1,
	}
	return (
		<>
			<SEO
				title="FRONTEND TOOLBOX"
				description="A list of handpicked resources for frontend devs"
				image="https://imgur.com/RsBF6bW.png"
			/>
			{/* <Header /> */}
			<div className="flex">
				<SideBar />

				<div className="hidden w-full h-screen max-w-xs xl:block" />
				<div className="w-screen bg-cloud-100 dark:bg-cloud-800 md-px-6 xl:pl-6 ">
					<section className=" body-font">
						<div className="container px-4 pb-12 mx-auto pt-36">
							<div className="fixed z-30 px-4 pt-4 pb-2 bg-white rounded-full shadow top-none bottom-5 right-5 md:top-10 md:bottom-auto md:right-10 dark:bg-cloud-500 ">
								<ThemeToggle />
							</div>
							<div className="flex flex-col w-full mb-12 ">
								<h2 className="mb-2 font-semibold tracking-widest uppercase text-indigo title-font">
									Frontend Toolbox
								</h2>
								<h1 className="mb-4 text-5xl font-semibold tracking-tighter xl:text-7xl text-gradient sm:text-6xl title-font">
									A list of handpicked
									<br className="" />
									resources for frontend devs
								</h1>
								<p className="text-xl font-medium leading-relaxed tracking-tight text-gray-600 dark:text-gray-300 lg:max-w-xl">
									There are lots of tools to choose from, and
									it’s hard to find the right ones. That’s why
									I created this list – so you save time by
									easily finding the best stuff out there.
								</p>
							</div>
						</div>
					</section>

					<div className="container px-4 mx-auto">
						{Object.keys(resources).map(item => (
							<section
								id={item.toLowerCase()}
								className="pt-32 xl:pt-12"
							>
								<div key={item}>
									<h2 className="mb-1 text-3xl font-semibold tracking-widest md:px-6 text-indigo title-font">
										{item}
									</h2>
									<div className="text-left">
										<MasnoryContainer
											breakpointCols={
												breakpointColumnsObj
											}
											className="masonry-grid"
											columnClassName="masonry-grid_column"
										>
											{resources[item].map(
												(resource, index) => (
													<Card
														key={index}
														url={resource}
													/>
												)
											)}
										</MasnoryContainer>
									</div>
									<br />
									<br />
								</div>
							</section>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

export default Resources
