const resources = {
  Icons: [
    'https://heroicons.com/?ref=akshay.rocks',
    'https://ionicons.com/?ref=akshay.rocks',
    'https://primer.style/octicons/?ref=akshay.rocks',
    'https://feathericons.com/?ref=akshay.rocks',
    'https://phosphoricons.com/?ref=akshay.rocks',
    'http://css.gg/?ref=akshay.rocks',
    'https://icons8.com/animated-icons?ref=akshay.rocks',
    // "https://vorillaz.github.io/devicons/#/main?ref=akshay.rocks",
    'https://github.com/tabler/tabler-icons?ref=akshay.rocks',
    'https://www.visiwig.com/icons/?ref=akshay.rocks',
    'https://heroicons.dev/?ref=akshay.rocks',
    'https://remixicon.com/?ref=akshay.rocks',
    'https://iconscout.com/unicons?ref=akshay.rocks',
  ],
  Illustrations: [
    'https://illlustrations.co/?ref=akshay.rocks',
    'https://freeillustrations.xyz/?ref=akshay.rocks',
    'https://stubborn.fun/?ref=akshay.rocks',
    'https://undraw.co/?ref=akshay.rocks',
    'https://www.manypixels.co/gallery/?ref=akshay.rocks',
    'https://www.uistore.design/items/loomies-free-illustration/?ref=akshay.rocks',
    // "https://iradesign.io/?ref=akshay.rocks",
    'https://storyset.com/?ref=akshay.rocks',
    'https://outlane.co/topics/freebie/?ref=akshay.rocks',
    'https://interfacer.xyz/?ref=akshay.rocks',
    'https://bigheads.io/?ref=akshay.rocks',
    'https://getavataaars.com/?ref=akshay.rocks',
  ],
  // "tailwind css": [
  //   "https://tailwindui.com/?ref=akshay.rocks",
  //   "https://www.creative-tim.com/learning-lab/tailwind-starter-kit/presentation?ref=akshay.rocks",
  //   "https://maizzle.com/?ref=akshay.rocks",
  //   "https://play.tailwindcss.com/?ref=akshay.rocks",
  //   // "https://tailblocks.cc/?ref=akshay.rocks",
  // ],
  // inspiration: [
  //   "https://onepagelove.com/?ref=akshay.rocks",
  //   "https://brandcolors.net/?ref=akshay.rocks",
  //   "https://dribbble.com/?ref=akshay.rocks",
  // ],
  'UI Design': [
    'https://glassmorphism.com?ref=akshay.rocks',
    'https://app.haikei.app?ref=akshay.rocks',
    'https://www.checklist.design/?ref=akshay.rocks',
    'https://www.landingfolio.com/?ref=akshay.rocks',
    'https://www.colorsandfonts.com/?ref=akshay.rocks',
    'https://www.svgbackgrounds.com/?ref=akshay.rocks',
    'https://www.shapedivider.app/?ref=akshay.rocks',
    'https://blocks.wickedtemplates.com/?ref=akshay.rocks',
    'https://cssgrid-generator.netlify.app/?ref=akshay.rocks',
    'https://www.heropatterns.com/?ref=akshay.rocks',
    'https://humanebydesign.com/?ref=akshay.rocks',
    'https://onepagelove.com/?ref=akshay.rocks',
    'https://refactoringui.com/book/?ref=akshay.rocks',
    'https://www.booklets.io/?ref=akshay.rocks',
    'https://collectui.com/?ref=flatuicolors?ref=akshay.rocks',
    'https://uiprint.co/?ref=akshay.rocks',
    'https://airbnb.design/lottie/?ref=akshay.rocks',
    'https://gooey-react.netlify.app/?ref=akshay.rocks',
    'https://principles.design/?ref=akshay.rocks',
  ],
  Learning: [
    'https://htmlreference.io/?ref=akshay.rocks',
    'http://cssreference.io/?ref=akshay.rocks',
    'https://marksheet.io/?ref=akshay.rocks',
    'https://javascript.info/?ref=akshay.rocks',
    'https://egghead.io/?ref=akshay.rocks',
    'https://roadmap.sh/frontend?ref=akshay.rocks',
    'https://devhints.io/?ref=akshay.rocks',
    'https://jgthms.com/web-design-in-4-minutes/?ref=akshay.rocks',
    'https://www.theodinproject.com/home?ref=akshay.rocks',
    'https://www.freecodecamp.org/?ref=akshay.rocks',
    'https://scrimba.com/g/gflexbox?ref=akshay.rocks',
    'http://flexboxfroggy.com/?ref=akshay.rocks',
    'https://enlight.nyc/?ref=akshay.rocks',
    'https://maggieappleton.com/garden?ref=akshay.rocks',
    'https://internetingishard.com/?ref=akshay.rocks',
  ],
  Blogs: [
    'https://css-tricks.com/?ref=akshay.rocks',
    'https://www.digitalocean.com/community/tutorials?ref=akshay.rocks',
    'https://www.joshwcomeau.com/?ref=akshay.rocks',
    'https://leerob.io/blog?ref=akshay.rocks',
    'https://blog.logrocket.com/?ref=akshay.rocks',
    'https://scotch.io/?ref=akshay.rocks',
    'https://www.kirupa.com/?ref=akshay.rocks',
    'https://www.freecodecamp.org/news/tag/web-development/?ref=akshay.rocks',
  ],

  Color: [
    'https://flatuicolors.com/?ref=akshay.rocks',
    'https://uigradients.com/?ref=akshay.rocks',
    'https://colorsinspo.com/?ref=akshay.rocks',
    'https://mycolor.space/?ref=akshay.rocks',
    'https://colorhunt.co/?ref=akshay.rocks',
    'https://cssgradient.io/?ref=akshay.rocks',
    'https://brandcolors.net/?ref=akshay.rocks',
    'https://coolors.co/?ref=akshay.rocks',
  ],
  Miscellaneous: [
    'https://headlessui.dev/?ref=akshay.rocks',
    'https://coding-fonts.css-tricks.com?ref=akshay.rocks',
    'https://draculatheme.com/?ref=akshay.rocks',
    'https://caferati.me/demo/react-awesome-button?ref=akshay.rocks',
    'https://fullpage.caferati.me/?ref=akshay.rocks',
    'https://hamburger-react.netlify.com/?ref=akshay.rocks',
    'https://www.wappalyzer.com/?ref=akshay.rocks',
    'https://react-hot-toast.com/?ref=akshay.rocks',
    'https://caniuse.com/?ref=akshay.rocks',
    'https://swr.vercel.app/?ref=akshay.rocks',
    'https://react-table.tanstack.com/?ref=akshay.rocks',
    'https://react-query.tanstack.com/?ref=akshay.rocks',
    'https://reactour.js.org/?ref=akshay.rocks',
    'https://v2.docusaurus.io/?ref=akshay.rocks',
    'https://chakra-ui.com/?ref=akshay.rocks',
    'https://storybook.js.org/?ref=akshay.rocks',
    'https://www.react-spring.io/?ref=akshay.rocks',
    'https://www.framer.com/motion/?ref=akshay.rocks',
    'https://devdojo.com/?ref=akshay.rocks',
    'https://carbon.now.sh/?ref=akshay.rocks',
    'https://uses.tech?ref=akshay.rocks',
    'https://react-hook-form.com/?ref=akshay.rocks',
    'https://tailwind.ink?ref=akshay.rocks',
    'https://ricostacruz.com/nprogress/?ref=akshay.rocks',
    'https://www.netlify.com/?ref=akshay.rocks',
    'https://webframe.xyz/?ref=akshay.rocks',
    'https://untools.co/?ref=akshay.rocks',
    'https://serverless.css-tricks.com/?ref=akshay.rocks',
    'https://pfpmaker.com/?ref=akshay.rocks',
    'https://a11y.coffee/?ref=akshay.rocks',
    'https://www.copysmith.ai/?ref=akshay.rocks',
    // "https://dndkit.com/?ref=akshay.rocks",
  ],
  YouTube: [
    'https://www.youtube.com/channel/UCW5YeuERMmlnqo4oq8vwUpg?ref=akshay.rocks',
    'https://www.youtube.com/channel/UC29ju8bIPH5as8OGnQzwJyA?ref=akshay.rocks',
    'https://www.youtube.com/channel/UC7Wpv0Aft4NPNhHWW_JC4GQ?ref=akshay.rocks',
    'https://www.youtube.com/channel/UCWv7vMbMWH4-V0ZXdmDpPBA?ref=akshay.rocks',
    'https://www.youtube.com/channel/UC-91UA-Xy2Cvb98deRXuggA?ref=akshay.rocks',
    'https://www.youtube.com/channel/UCsBjURrPoezykLs9EqgamOA?ref=akshay.rocks',
    'https://www.youtube.com/channel/UClb90NQQcskPUGDIXsQEz5Q?ref=akshay.rocks',
    'https://www.youtube.com/channel/UC-T8W79DN6PBnzomelvqJYw?ref=akshay.rocks',
    'https://www.youtube.com/channel/UC-8QAzbLcRglXeN_MY9blyw?ref=akshay.rocks',
    'https://www.youtube.com/channel/UCFbNIlppjAuEX4znoulh0Cw?ref=akshay.rocks',
  ],
};

export default resources;
