import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Close,
  Facebook,
  Reddit,
  Twitter,
  LinkedIn,
  Telegram,
  Link,
} from './icons';
import PrimaryButton from './primaryButton';
import ExternalLink from './externalLink';

const ShareDialog = ({ isOpen, setIsOpen }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (event) => {
    if (!navigator.clipboard) {
      // Clipboard API not available
      return;
    }
    /* Get the text field */
    const copyText = document.getElementById('sharelink').innerText;

    try {
      await navigator.clipboard.writeText(copyText);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 opacity-100 dark:backdrop-saturate-50 dark:backdrop-hue-rotate-15 backdrop-brightness-75 backdrop-filter backdrop-blur" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-bottom md:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl dark:text-white dark:bg-cloud-700 md:rounded-2xl rounded-t-2xl">
              <div className="flex items-start justify-between p-5 pb-5 border-b border-solid rounded-t border-cloud-100 dark:border-cloud-900 ">
                <h3 className="pt-3 text-3xl font-semibold text-gradient">
                  Spread the Word!
                </h3>
                <button
                  className="float-right p-1 ml-auto text-3xl leading-none border-0 outline-none opacity-90 hover:opacity-100 focus:outline-none"
                  onClick={() => setIsOpen(false)}
                >
                  <Close className="w-10 h-10 " color="#D85B95" />
                </button>
              </div>
              <div className="relative flex-auto p-8">
                <p className="mb-2 text-lg leading-relaxed ">
                  Share this page via
                </p>
                <div className="flex space-x-1">
                  <ExternalLink
                    underline={false}
                    href="https://www.facebook.com/sharer/sharer.php?u=https://akshay.rocks/resources"
                  >
                    <Facebook className="w-14 h-14 text-facebook" />
                  </ExternalLink>
                  <ExternalLink
                    underline={false}
                    href="https://twitter.com/intent/tweet?url=https://akshay.rocks/resources&text=A%20list%20of%20handpicked%0Aresources%20for%20frontend%20devs"
                  >
                    <Twitter className="w-14 h-14 text-twitter" />
                  </ExternalLink>
                  <ExternalLink
                    underline={false}
                    href="https://www.reddit.com/submit?url=https%3A%2F%2Fakshay.rocks%2Fresources%2F&title=A%20list%20of%20handpicked%20resources%20for%20frontend%20devs"
                  >
                    <Reddit className="w-14 h-14 text-reddit" />
                  </ExternalLink>
                  <ExternalLink
                    underline={false}
                    href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fakshay.rocks%2Fresources%2F"
                  >
                    <LinkedIn className="w-14 h-14 text-linkedin" />
                  </ExternalLink>
                  <ExternalLink
                    underline={false}
                    href="https://telegram.me/share/?url=https%3A%2F%2Fakshay.rocks%2Fresources%2F"
                  >
                    <Telegram className="w-14 h-14 text-telegram" />
                  </ExternalLink>
                </div>
                <p className="mt-6 mb-2 text-lg leading-relaxed ">
                  Or copy link
                </p>
                <div className="flex items-center p-1 border-2 border-indigo-400 rounded dark:border-indigo-700">
                  <p className="flex-auto pl-2 text-sm md:text-base">
                    <Link className="inline w-8 h-8 mr-2 text-indigo dark:text-indigo-700" />
                    <span id="sharelink">https://akshay.rocks/resources/</span>
                  </p>
                  <div className="flex-shrink hidden !rounded-lg md:block">
                    <PrimaryButton onClick={copyToClipboard}>
                      {copied ? 'Copied' : 'Copy'}
                    </PrimaryButton>
                  </div>
                </div>

                <PrimaryButton
                  onClick={copyToClipboard}
                  className="justify-center block w-full mt-2 md:hidden"
                >
                  {copied ? 'Copied' : 'Copy'}
                </PrimaryButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareDialog;
